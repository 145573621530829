import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { ConfirmationDialogComponent } from 'src/app/modal/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list-booking',
  templateUrl: './list-booking.component.html',
  styleUrls: ['./list-booking.component.css']
})
export class ListBookingComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;
  searchForm: FormGroup;
  editCustomerForm: FormGroup
  clearCashForm: FormGroup
  hideFilter = false;
  bookingType = "booking";
  totalCost: any
  totalCount: number;
  countries;
  states: any = [];
  SEARCH_TIMER: any = '';
  editCustomerId: any;
  submitted: boolean = false;
  religionLists: any = [];
  paymentModeLists: any = []
  nationalityLists: any = []
  nomineeRelationLists: any = []
  filterStates: any;
  projectsList: any = [];
  customersList: any = [];
  editBookingId: any;
  totalReceivedAmount = 0;
  totalAmount = 0;
  totalDuesAmount = 0;
  isAdditionalFilter: boolean = false;
  toDate: any;
  fromDate: any
  perPageItems: number = 10;
  p: number = 1
  saleType: string;
  project: string;
  status: string;
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  constructor(private formBuilder: FormBuilder, private api: ApiService, private confirmationDialogService: ConfirmationDialogService) { }
  ngOnInit() {
    this.getCountry();
    this.getState()
    this.getBookingList({
      "page_number": 1,
      "limit": 10
    });
    this.fields();
    this.searchForm = this.formBuilder.group({
      projectName: [''],
      saleType: [''],
      To: [null],
      From: [null],
      bookingStatus: [null]
    });


    this.editCustomerForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobileNo: ['', Validators.required],
      alternate_number: [''],
      pan: [''],
      adhaar: [''],
      address: [''],
      religion: [''],
      nationality: [''],
      country_id: [''],
      state_id: [''],
      city: [''],
      relation: [''],
      nominee_name: [''],
      gender: [''],
      email: [''],
      bookingStatus: ['']
    });


    this.clearCashForm = this.formBuilder.group({
      transaction_id: ['', Validators.required],
      description: ['', Validators.required],

    });
  }

  getBookingList(extra = {}) {
    this.response = true;
    this.totalReceivedAmount = 0;
    this.totalDuesAmount = 0;
    this.totalAmount = 0;
    let filter = { type: this.bookingType, school_user_id: localStorage.getItem('school_user_id') }
    filter = { ...filter, ...extra }
    this.p = extra["page_number"];
    this.api.post('booking-list', filter).subscribe(resp => {
      if (resp.code == 200) {
        this.response = false;
        this.p = extra['page_number'];
        this.totalCount = resp.result.total;
        resp.result.list.forEach((ele) => {
          if (!this.projectsList.find(item => item.id == ele.project_id)) {
            this.projectsList.push({ id: ele.project_id, projectName: ele.project_name });
          }

          if (!this.customersList.find(cus => cus.id == ele.customer_id)) {
            this.customersList.push({ id: ele.customer_id, customerName: ele.customer_name });
          }
        })

        this.items = resp.result.list.map((element, index) => {
          this.totalAmount = Number(this.totalAmount) + Number(element.total_cost)
          this.totalReceivedAmount = Number(this.totalReceivedAmount) + Number(element.total_received_amount)
          this.totalDuesAmount = Number(this.totalDuesAmount) + Number(element.current_dues)
          return element;
        })
        console.log('count', this.totalCount)
      }
      else {
        this.response = false;
      }
    });
  }
  get f() {
    return this.searchForm.controls;
  }

  get cf() {
    return this.editCustomerForm.controls;
  }
  get clear() {
    return this.clearCashForm.controls;
  }

  showHideFilter() {
    this.hideFilter = !this.hideFilter;
  }
  deleteItem(id, reason) {
    let item = { bookingId: id, reason: reason }
    this.response = true;
    this.api.delete('booking-delete', item).subscribe(
      data => {
        // if(data.code==200){
        this.ngOnInit()
        this.response = false;
        // }  
        // else{
        //   this.response=false;
        // }
      }, err => {
        this.response = false;
        console.log(err);
      }
    )
  };
  public openConfirmationDialog(id) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteItem(id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :)', 'error')
      }
    })
  }
  public changeBookingType(data) {
    this.response = true;
    this.bookingType = data;
    this.getBookingList({
      page_number: 1,
      limit: 10
    });
  }

  onChangePage(pageOfItems) {
    this.getBookingList({
      "page_number": pageOfItems,
      "limit": 10,
      "isAdditionalFilter": this.isAdditionalFilter,
      "toDate": this.toDate,
      "fromDate": this.fromDate,
      "saleType": this.saleType,
      "project": this.project,
      "status": this.status,
    });
  }

  editCustomerData(bookingId, customerId) {
    this.editCustomerId = customerId;
    this.editBookingId = bookingId;

    this.items.forEach((item) => {
      if (item.customer_id == customerId) {
        this.editCustomerForm.patchValue({ customer_id: item.customer_id })
        this.editCustomerForm.patchValue({ name: item.customer_name })
        this.editCustomerForm.patchValue({ mobileNo: item.mobile_no })
        this.editCustomerForm.patchValue({ alternate_number: item.alternate_mob_no })
        this.editCustomerForm.patchValue({ pan: item.pan_no })
        this.editCustomerForm.patchValue({ address: item.address })
        this.editCustomerForm.patchValue({ adhaar: item.aadhar_no })
        this.editCustomerForm.patchValue({ religion: item.religion })
        this.editCustomerForm.patchValue({ nationality: item.nationality })
        this.editCustomerForm.patchValue({ country_id: item.country })
        this.editCustomerForm.patchValue({ state_id: item.state })
        this.editCustomerForm.patchValue({ city: item.city })
        this.editCustomerForm.patchValue({ relation: item.nominee_relation })
        this.editCustomerForm.patchValue({ nominee_name: item.nominee_name })
        this.editCustomerForm.patchValue({ gender: item.gender })
        this.editCustomerForm.patchValue({ email: item.email })
        this.editCustomerForm.patchValue({ bookingStatus: item.status })
      }
    })
  }


  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
        if (data) {
          this.onChange(data.country_id);
          this.filterStates = data;
        }
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  onChange(country_d) {
    let newStates = [];
    if (this.states.length > 0) {
      this.states.forEach(function (value) {
        if (value.country_id == country_d) {
          newStates.push(value);
        }
      });
    }
    this.filterStates = newStates;
  }

  setStateByCountryId(countryId) {
    let newStates = [];
    if (this.states.length > 0) {
      this.states.forEach(function (value) {
        if (value.country_id == countryId) {
          newStates.push(value);
        }
      });
    }
    this.filterStates = newStates;
  }

  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      (data) => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  customerSubmit(customerFormData) {
    let request = this.editCustomerForm.value;
    this.response = true;
    this.submitted = true;
    if (this.editCustomerForm.invalid) {
      this.response = false;
      this.submitted = false;
      Swal.fire('Error', 'Please fill required field', 'error');
      return
    }
    request.customerId = this.editCustomerId;
    request.bookingId = this.editBookingId;
    this.api.post('update-customer-data', request).subscribe(
      data => {
        if (data.code == 200) {
          document.getElementById("closeEditBookingDataModal").click();
          Swal.fire('Success', 'Customer Details Updated Successfully', 'success');
          this.ngOnInit();
          this.response = false;
          this.getBookingList({
            page_number: 1,
            limit: 10
          });
        }
        else {

          this.response = false;
        }
      }, err => {
        this.response = false;
        console.log(err);
      }
    )
  };

  fields() {
    // this.api.post('booking-fields' + "?id=" + localStorage.getItem('user_id'),null)
    this.api.post('organization/list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(resp => {
      if (resp.response.length) {
        resp.response.forEach((field) => {
          if (field.field_type_id == 4) {
            this.religionLists.push({ 'religionId': field.field_type_id, 'religionName': field.name })
          }
          else if (field.field_type_id == 25) {
            this.paymentModeLists.push({ 'paymentModeId': field.field_type_id, 'paymentModeName': field.name })
          }
          else if (field.field_type_id == 10) {
            this.nationalityLists.push({ 'nationalityId': field.field_type_id, 'nationalityName': field.name })
          }
          else if (field.field_type_id == 47) {
            this.nomineeRelationLists.push({ 'nomineeRelationId': field.field_type_id, 'nomineeRelationName': field.name })
          }
        })
      }
    })
  }

  searchValue = "";
  filterSearch(value) {
    if (this.SEARCH_TIMER != '') {
      clearTimeout(this.SEARCH_TIMER);
    }
    this.SEARCH_TIMER = setTimeout(() => {
      if (value != '') {
        this.searchValue = value;
        this.getBookingList({
          "search": this.searchValue,
          "page_number": 1,
          "limit": 10,
          "isAdditionalFilter": this.isAdditionalFilter,
          "toDate": this.toDate,
          "fromDate": this.fromDate,
          // "leadPriority": this.leadPriority,
          // "productService":this.productService,
          // "leadSource":this.leadSource,
          // "assignUser":this.assignUsers,
          // "status":this.filterBy
        });
      } else {
        this.searchValue = "";
        this.getBookingList({
          // "status":this.filterBy,
          "search": this.searchValue,
          "page_number": 1,
          "limit": 10
        });
      }
    }, 1000)
  }

  isFilter: boolean = true;
  onSubmit(actionType) {
    this.isFilter = !this.isFilter;
    if (actionType == "filter") {
      var datePipe = new DatePipe('en-US');
      let additionalFilter = this.searchForm.value;
      // if (additionalFilter.customerName) {

      // }
      this.isAdditionalFilter = true;
      if (additionalFilter.To) {
        this.toDate = datePipe.transform(new Date(additionalFilter.To["year"], additionalFilter.To["month"] - 1, additionalFilter.To["day"]), 'yyyy-MM-dd')
      }
      if (additionalFilter.From) {
        this.fromDate = datePipe.transform(new Date(additionalFilter.From["year"], additionalFilter.From["month"] - 1, additionalFilter.From["day"]), 'yyyy-MM-dd');
      }
      if (additionalFilter.saleType) {
        this.saleType = additionalFilter.saleType;
      }
      if (additionalFilter.projectName) {
        this.project = additionalFilter.projectName;
      }
      if (additionalFilter.bookingStatus) {
        this.status = additionalFilter.bookingStatus;
      }
      console.log("this.fromDate", this.fromDate)
      console.log("this.toDate", this.toDate)

      this.getBookingList({
        "page_number": 1,
        "limit": 10,
        "isAdditionalFilter": this.isAdditionalFilter,
        "toDate": this.toDate,
        "fromDate": this.fromDate,
        "saleType": this.saleType,
        "project": this.project,
        "status": this.status,
      });

    }
    else if (actionType == "refresh") {
      this.isAdditionalFilter = false;
      this.toDate = '';
      this.fromDate = '';
      this.saleType = '';
      this.project = '';
      this.status = '';
      this.ngOnInit();
    }
  }


  generatePaymentReport(payment_id) {
    this.response = true;
    this.api.getPdf('customer/payment/generate-report?payment_id=' + payment_id).subscribe((data: any) => {

      const blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = payment_id + ".pdf";
      link.click();
      this.response = false;


    });
  }



}