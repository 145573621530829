import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings :IDropdownSettings= {};
  paymentForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  constructor(private router: Router,private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {
  }
  actionType:string="ADD NEW";
  ngOnInit() {
    this.actionType="ADD NEW";
    this.id = this.route.snapshot.params['id'];
    if(this.id>0){
      this.actionType="Edit";
    }
    this.newLevelNo();
    this.paymentForm = new FormGroup({
      first: new FormControl(''),
      second: new FormControl(''),
      third: new FormControl(''),
      forth: new FormControl(''),
      fifth: new FormControl(''),
      sixth: new FormControl(''),
      seventh: new FormControl(''),
      eighth: new FormControl(''),
      ninth: new FormControl(''),
      tenth: new FormControl(''),
      level_no: new FormControl(''),
      area_condition: new FormControl(''),
      booking: new FormControl('')
    });
  }

  get f() { return this.paymentForm.controls; }

  newLevelNo() {
    let request = {
      school_user_id:localStorage.getItem('school_user_id')
    }
    this.response = true;
    this.api.getNewEmiLevel(request).subscribe(
      (data) => {
        this.response = false;
        if (data.code == 200) {
          this.paymentForm = this.formBuilder.group({
            first: [],
            second: [],
            third: [],
            forth: [],
            fifth: [],
            sixth: [],
            seventh: [],
            eighth: [],
            ninth: [],
            tenth: [],
            level_no: [data.result],
            area_condition: [],
            booking: [],
          });
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found, to found new level no', 'error');
      },()=>{
        this.response = false;
      }
    )
  }

  onSubmit(item: any) {
    let request = item.value;
    request.school_user_id = localStorage.getItem('school_user_id');
    this.response = true;
    this.api.addEmiLevel(request).subscribe(
      (data) => {
        this.response = false;
        if (data.code == 200) {
            this.paymentForm.reset();
            this.submitted = false;
            Swal.fire('Success', data.message, 'success');
            this.ngOnInit();
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found', 'error');
      },()=>{
        this.response = false;
      }
    )
  }
}
