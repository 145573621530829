import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { ListComponent } from './page/sms-plan/list/list.component';
import { AddComponent } from './page/sms-plan/add/add.component';
import { SmsRequestComponent } from './page/sms-request/sms-request.component';
import { SmsReportComponent } from './page/sms-report/sms-report.component';
import { SchoolAddComponent } from './page/school/school-add/school-add.component';
import { SchoolListComponent } from './page/school/school-list/school-list.component';
import { StateAddComponent } from './page/state/state-add/state-add.component';
import { StateListComponent } from './page/state/state-list/state-list.component';
import { ModuleListComponent } from './page/module/module-list/module-list.component';
import { ModuleAddComponent } from './page/module/module-add/module-add.component';
import { ModuleEditComponent } from './page/module/module-edit/module-edit.component';
import { SchoolEditComponent } from './page/school/school-edit/school-edit.component';
import { UserAddComponent } from './page/user/user-add/user-add.component';
import { UserListComponent } from './page/user/user-list/user-list.component';
import { SessionAddComponent } from './page/school/session-add/session-add.component';
import { SessionListComponent } from './page/school/session-list/session-list.component';
import { AddComponent as BatchAdd } from './page/school/batch/add/add.component';
import { ListComponent as BatchList } from './page/school/batch/list/list.component';
import { AddComponent as SubjectAdd } from './page/school/subject/add/add.component';
import { ListComponent as SubjectList } from './page/school/subject/list/list.component';
import { AddComponent as CategoryAdd } from './page/school/category/add/add.component';
import { ListComponent as CategoryList } from './page/school/category/list/list.component';
import { AddComponent as ClassAdd } from './page/school/class/add/add.component';
import { ListComponent as ClassList } from './page/school/class/list/list.component';
import { AddComponent as FeeAdd } from './page/school/fee/add/add.component';
import { ListComponent as FeeList } from './page/school/fee/list/list.component';
import { AddComponent as FeeAssignAdd } from './page/school/feeassign/add/add.component';
import { ListComponent as FeeAssignList } from './page/school/feeassign/list/list.component';
import { AddComponent as FineAdd } from './page/school/fine/add/add.component';
import { ListComponent as FineList } from './page/school/fine/list/list.component';
import { AddComponent as EnrollmentAdd } from './page/student/enrollment/add/add.component';
import { ListComponent as EnrollmentList } from './page/student/enrollment/list/list.component';
import { AddComponent as EnrollmentSettingAdd } from './page/student/enrollment-setting/add/add.component';
import { AddComponent as FieldAdd } from './page/field/add/add.component';
import { ListComponent as FieldList } from './page/field/list/list.component';
import { AddComponent as AssignDesignationAdd } from './page/hr/assign-designation/add/add.component';
import { ListComponent as AssignDesignationList } from './page/hr/assign-designation/list/list.component';
import { AddComponent as MasterAdd } from './page/hr/master/add/add.component';
import { ListComponent as MasterList } from './page/hr/master/list/list.component';
import { AddComponent as GridAdd } from './page/hr/grid/add/add.component';
import { ListComponent as GridList } from './page/hr/grid/list/list.component';
import { AddComponent as EmployeeAdd } from './page/employee/add/add.component';
import { ListComponent as EmployeeList } from './page/employee/list/list.component';
import { ListComponent as EmployeeSetting } from './page/employee/setting/list/list.component';
import { FileUploadComponent as EmployeeFileUpload } from './page/hr/file-upload/file-upload.component';
import { AddComponent as StudentFileUpload } from './page/student/file-upload/add/add.component';
import { ListComponent as AssignClassTeacherList } from './page/hr/class-teacher/list/list.component';
import { AddComponent as AssignClassTeacherAdd } from './page/hr/class-teacher/add/add.component';
import { ListComponent as AssignClassSubjectList } from './page/hr/class-subject/list/list.component';
import { AddComponent as AssignClassSubjectAdd } from './page/hr/class-subject/add/add.component';
import { ListComponent as LeadList } from './page/lead/list/list.component';
import { AddComponent as LeadAdd } from './page/lead/add/add.component';
import { ListComponent as PartyList } from './page/party/master/list/list.component';
import { AddComponent as PartyAdd } from './page/party/master/add/add.component';
import { ListComponent as TransportList } from './page/party/transport/list/list.component';
import { AddComponent as TransportAdd } from './page/party/transport/add/add.component';
import { ListComponent as PickDropList } from './page/party/pickup-drop/list/list.component';
import { AddComponent as PickDropAdd } from './page/party/pickup-drop/add/add.component';
import { ListComponent as AssignPickDropList } from './page/party/assign-pick-drop/list/list.component';
import { AddComponent as AssignPickDropAdd } from './page/party/assign-pick-drop/add/add.component';
import { ListComponent as FeeCollectionList } from './page/fee/collection/list/list.component';
import { AddComponent as FeeCollectionAdd } from './page/fee/collection/add/add.component';
import { CollectionsComponent } from './page/fee/collections/collections.component';
import { ListComponent as RegistrationList } from './page/student/registration/list/list.component';
import { AddComponent as RegistrationAdd } from './page/student/registration/add/add.component';
import { ChangeComponent as PasswordChange } from './page/password/change/change.component';
import { ResetComponent as PasswordReset } from './page/password/reset/reset.component';
import { NewFeeComponent } from './page/school/new-fee/new-fee.component';
import { NewFeeListComponent } from './page/school/new-fee-list/new-fee-list.component';
import { AddBookingComponent } from './page/realstate/booking/add/add.component'
import { AddPenaltyComponent } from './page/realstate/penalty/add-penalty/add-penalty.component';
import { AddchargesComponent } from './page/realstate/charges/addcharges/addcharges.component';
import { ListChargesComponent } from './page/realstate/list-charges/list-charges.component';
import { ListPenaltyComponent } from './page/realstate/list-penalty/list-penalty.component';
import { ListBookingComponent } from './page/realstate/booking/list-booking/list-booking.component';
import { ProjectManageComponent } from './page/realstate/project-manage/project-manage.component';
import { AddProjectManageComponent } from './page/realstate/add-project-manage/add-project-manage.component';
import { CustomerPaymentReportComponent } from './page/realstate/realstate/customer-payment-report/customer-payment-report.component';
import { CustomerComissionComponent } from './page/realstate/customer-comission/customer-comission.component';



const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'sms-plan', component: ListComponent },
  { path: 'sms-plan/add', component: AddComponent },
  { path: 'sms-plan/edit/:id', component: AddComponent },
  { path: 'sms-request', component: SmsRequestComponent },
  { path: 'sms-request/:add', component: SmsRequestComponent },
  { path: 'sms-report', component: SmsReportComponent },
  { path: 'school/add', component: SchoolAddComponent },
  { path: 'school/edit/:id', component: SchoolEditComponent },
  { path: 'school', component: SchoolListComponent },
  { path: 'session/add', component: SessionAddComponent },
  { path: 'session/edit/:id', component: SessionAddComponent },
  { path: 'session/list', component: SessionListComponent },
  { path: 'batch/list', component: BatchList },
  { path: 'batch/add', component: BatchAdd },
  { path: 'batch/edit/:id', component: BatchAdd },
  { path: 'subject/list', component: SubjectList },
  { path: 'subject/add', component: SubjectAdd },
  { path: 'subject/edit/:id', component: SubjectAdd },
  { path: 'category/list', component: CategoryList },
  { path: 'category/add', component: CategoryAdd },
  { path: 'category/edit/:id', component: CategoryAdd },
  { path: 'class/add', component: ClassAdd },
  { path: 'class/edit/:id', component: ClassAdd },
  { path: 'class/list', component: ClassList },
  { path: 'fee/add', component: FeeAdd },
  { path: 'fee/list', component: FeeList },
  { path: 'fee/edit/:id', component: FeeAdd },
  { path: 'fee-assign/add', component: FeeAssignAdd },
  { path: 'fee-assign/list', component: FeeAssignList },
  { path: 'fee-assign/edit/:id', component: FeeAssignAdd },
  { path: 'fine/add', component: FineAdd },
  { path: 'fine/edit/:id', component: FineAdd },
  { path: 'fine/list', component: FineList },
  { path: 'state/add', component: StateAddComponent },
  { path: 'state/list', component: StateListComponent },
  { path: 'module/edit/:id', component: ModuleEditComponent },
  { path: 'module/add', component: ModuleAddComponent },
  { path: 'module/list', component: ModuleListComponent },
  { path: 'user/create', component: UserAddComponent },
  { path: 'user/list', component: UserListComponent },
  { path: 'enrollment/add', component: EnrollmentAdd },
  { path: 'enrollment/edit/:id', component: EnrollmentAdd },
  { path: 'enrollment/list', component: EnrollmentList },
  { path: 'enrollment/setting', component: EnrollmentSettingAdd },
  { path: 'field/add', component: FieldAdd },
  { path: 'field/edit/:id', component: FieldAdd },
  { path: 'field/list', component: FieldList },
  { path: 'assign-designation/add', component: AssignDesignationAdd },
  { path: 'assign-designation/edit/:id', component: AssignDesignationAdd },
  { path: 'assign-designation/list', component: AssignDesignationList },
  { path: 'master/edit/:id', component: MasterAdd },
  { path: 'master/add', component: MasterAdd },
  { path: 'master/list', component: MasterList },
  { path: 'master/add', component: MasterAdd },
  { path: 'grid/list', component: GridList },
  { path: 'grid/add', component: GridAdd },
  { path: 'grid/edit/:id', component: GridAdd },
  { path: 'employee/add', component: EmployeeAdd },
  { path: 'employee/edit/:id', component: EmployeeAdd },
  { path: 'employee/list', component: EmployeeList },
  { path: 'employee/setting', component: EmployeeSetting },
  { path: 'employee/upload', component: EmployeeFileUpload },
  { path: 'student/upload', component: StudentFileUpload },
  { path: 'assign-class-teacher/add', component: AssignClassTeacherAdd },
  { path: 'assign-class-teacher/edit/:id', component: AssignClassTeacherAdd },
  { path: 'assign-class-teacher/list', component: AssignClassTeacherList },
  { path: 'assign-class-subject/add', component: AssignClassSubjectAdd },
  { path: 'assign-class-subject/edit/:id', component: AssignClassSubjectAdd },
  { path: 'assign-class-subject/list', component: AssignClassSubjectList },
  { path: 'lead/add', component: LeadAdd },
  { path: 'lead/edit/:id', component: LeadAdd },
  { path: 'lead/list', component: LeadList },
  { path: 'party/add', component: PartyAdd },
  { path: 'party/edit/:id', component: PartyAdd },
  { path: 'party/list', component: PartyList },
  { path: 'transport/add', component: TransportAdd },
  { path: 'transport/edit/:id', component: TransportAdd },
  { path: 'transport/list', component: TransportList },
  { path: 'pickup-drop/add', component: PickDropAdd },
  { path: 'pickup-drop/edit/:id', component: PickDropAdd },
  { path: 'pickup-drop/list', component: PickDropList },
  { path: 'assign-pickup-drop/add', component: AssignPickDropAdd },
  { path: 'assign-pickup-drop/edit/:id', component: AssignPickDropAdd },
  { path: 'assign-pickup-drop/list', component: AssignPickDropList },
  { path: 'fee-collection/add', component: FeeCollectionAdd },
  { path: 'fee-collection/edit/:id', component: FeeCollectionAdd },
  { path: 'fee-collection/list', component: FeeCollectionList },
  { path: 'fee/collection', component: CollectionsComponent },
  { path: 'student/registration/add', component: RegistrationAdd },
  { path: 'student/registration/edit/:id', component: RegistrationAdd },
  { path: 'student/registration/list', component: RegistrationList },
  { path: 'change/password', component: PasswordChange },
  { path: 'student/fee/list', component: NewFeeListComponent },
  { path: 'student/fee/store', component: NewFeeComponent },
  { path: 'reset/password', component: PasswordReset },
  { path: 'realstate/booking/list', component: ListBookingComponent },
  { path: 'realstate/booking/add', component: AddBookingComponent },
  { path: 'realstate/penalty/add', component: AddPenaltyComponent },
  { path: 'realstate/penalty/edit/:penaltyid', component: AddPenaltyComponent },
  { path: 'realstate/charges/add', component: AddchargesComponent },
  { path: 'realstate/charges/list', component: ListChargesComponent },
  { path: 'realstate/penalty/list', component: ListPenaltyComponent },
  { path: 'realstate/charges/edit/:chargeid', component: AddchargesComponent },
  { path: 'realstate/projectmanage', component: ProjectManageComponent },
  { path: 'realstate/addprojectmanage', component: AddProjectManageComponent },
  { path: 'realstate/customer-payment-report', component: CustomerPaymentReportComponent },
  { path: 'customers/comission', component: CustomerComissionComponent },

  { path: 'realstate', loadChildren: () => import('./page/realstate/realstate/realstate.module').then(m => m.RealstateModule) },
  { path: 'leads', loadChildren: () => import('./page/lead/lead.module').then(m => m.LeadModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
