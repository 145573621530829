import { Component, OnInit } from '@angular/core';
import { ApiService } from '../..///api.service';

import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  permission: any;
  permissions = [];
  user_name: string;
  user: any = {
    type: '',
    profile_image: "",
    name: ''
  };
  showHead: true;
  logo;
  appUrl;
  constructor(public router: Router, private api: ApiService,) { }

  ngOnInit() {
    if (localStorage.getItem('user_id')) {
      this.getPermission();
    }
    this.appUrl = this.api.appUrl + "public/";

  }

  getPermission() {
    // let data = {type:'modulepermission', id:localStorage.getItem('user_id')}
    let data = { type: 'user', id: localStorage.getItem('user_id') }
    this.api.post('organization/list', data).subscribe(
      result => {
        this.user = result;
        this.user = this.user.response;
        console.log("user", this.user);
        this.user_name = this.user.name ? this.user.name : '';
        this.logo = this.user.profile_image ? this.user.profile_image : '/assets/img/user_logo.png';
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  }

  Logout() {

    this.api.getItem('customer-logout').subscribe(
      resp => {
        console.log(resp)
      }
    )
    debugger;

    localStorage.clear();
    localStorage.removeItem('token')
    this.router.navigate(['/login']);


  }



}
